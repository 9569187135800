import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { AnimateSharedLayout, AnimatePresence, motion, useAnimation } from 'framer-motion'

import Layout from '../components/layout'
import Slider, { NextSlide, CurrentSlide, MobileNav } from '../components/slider'
import Button from '../components/button'
import SEO from '../components/seo'
import AnimatedPattern from '../components/animated-pattern'
import AnimatedArrow from '../components/animated-arrow'

// Assets
import Arrow from '../images/arrow.inline.svg'
import Pattern from '../images/tuya-pattern.inline.svg'

// Styles
import '../styles/pages/index.scss'

// variants
const backgroundVariants = {
  open: ({ full = '100%' }) => ({
    height: full,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  }),
  closed: ({ initial = 85, delay = 0 }) => ({
    height: initial,
    transition: {
      delay,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  }),
}
const variantsRevealParent = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}
const patternReveal = {
  open: {
    opacity: 1,
    pathLength: 1,

    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    pathLength: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}
const variantsReveal = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const MotionLink = motion.custom(Link)
const slideTimeout = 7
// Components
const HeroSection = ({ items, navbarData }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [isMouseOverTabs, setIsMouseOverTabs] = useState(false)
  const [isPanStarted, setIsPanStarted] = useState(false)
  const controls = useAnimation()
  useEffect(() => {
    controls.start({ width: '100%' })
  }, [controls])

  useEffect(() => {
    if (isMouseOverTabs) {
      controls.set({ width: 0 })
      controls.stop()
    } else {
      controls.start({
        width: '100%',
      })
    }
  }, [controls, isMouseOverTabs])

  useEffect(() => {
    let interval
    if (isMouseOverTabs || isPanStarted) {
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        setCurrentItemIndex(oldIndex => (oldIndex + 1) % items.length)
        controls.set({ width: 0 })
        controls.start({
          width: '100%',
        })
      }, slideTimeout * 1000)
    }
    return () => clearInterval(interval)
  }, [controls, items.length, isMouseOverTabs, isPanStarted])

  const currentItem = items[currentItemIndex]

  return (
    <motion.section
      className="hero-section md:h-screen relative"
      onPanStart={() => setIsPanStarted(true)}
      onPanEnd={(_, pointInfo) => {
        if (Math.abs(pointInfo.offset.x) > 100) {
          if (pointInfo.velocity.x > 0) {
            setCurrentItemIndex(oldIndex => (oldIndex - 1 + items.length) % items.length)
          } else {
            setCurrentItemIndex(oldIndex => (oldIndex + 1) % items.length)
          }
        }
        setIsPanStarted(false)
      }}
    >
      <div className=" mx-auto flex items-center md:items-end h-3/4  lg:px-11">
        <div className="relative z-30 flex flex-col max-w-screen-md ">
          <AnimateSharedLayout>
            <ul className="md:flex space-x-16 mb-4 hidden">
              {items.map((item, i) => (
                <li
                  className={`${
                    item.id === currentItem.id ? 'text-tuya-yellow' : 'text-white'
                  } text-2xl flex items-center`}
                  key={item.id}
                >
                  <Link
                    className="font-light flex items-center"
                    to={`/${item.pageSlug}`}
                    onMouseOver={() => setCurrentItemIndex(i)}
                    onFocus={() => setCurrentItemIndex(i)}
                    onMouseEnter={() => setIsMouseOverTabs(true)}
                    onMouseLeave={() => setIsMouseOverTabs(false)}
                  >
                    {item.id === currentItem.id && (
                      <motion.span
                        layoutId="arrow"
                        className="bg-white w-10 h-10 text-black rounded-full flex items-center justify-center mr-3 "
                      >
                        <Arrow className="w-3 h-3" />
                      </motion.span>
                    )}
                    {item.tabTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </AnimateSharedLayout>
          <motion.hr
            initial={{ width: 0 }}
            animate={controls}
            style={{ maxWidth: 700 }}
            transition={{
              duration: slideTimeout,
              ease: 'linear',
              type: 'tween',
            }}
            className="bg-white hidden md:block"
          />
          <div className="flex items-center text-white mb-8 md:hidden ">
            <span className="bg-white w-8 h-8 text-black rounded-full flex items-center justify-center mr-5 ">
              <Arrow className="w-2 h-2" />
            </span>
            <ul className="flex justify-between space-x-7 text-base font-light">
              {[items[currentItemIndex], items[(currentItemIndex + 1) % 3]].map((item, i) => (
                <li
                  className={`${item.id === currentItem.id ? 'text-tuya-yellow' : 'text-white'}`}
                  key={item.id}
                >
                  <Link to={item.pageSlug}>{item.tabTitle}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <AnimatePresence initial={false} exitBeforeEnter>
              <motion.h1
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
                exit={{
                  y: 20,
                  opacity: 0,
                  transition: { duration: 0.3, delay: 0.15 },
                }}
                transition={{ ease: 'easeInOut' }}
                key={currentItem.id}
                className="text-white leading-none hero-heading"
                dangerouslySetInnerHTML={{ __html: currentItem.title }}
              />
            </AnimatePresence>
            <AnimatePresence initial={false} exitBeforeEnter>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: { duration: 0.4, delay: 0.2 },
                }}
                exit={{
                  y: 20,
                  opacity: 0,
                  transition: { duration: 0.3 },
                }}
                transition={{ ease: 'easeInOut' }}
                key={currentItem.id}
                className="text-white md:text-lg font-light mb-10 md:mb-0"
              >
                {currentItem.subtitle}
              </motion.p>
            </AnimatePresence>
            <div className="flex md:hidden">
              {items.map((item, i) => (
                <span
                  key={item.id}
                  className={`${
                    item.id === currentItem.id ? 'bg-tuya-yellow' : 'bg-white opacity-30'
                  } ${i < items.length ? 'mr-3' : ''} w-2 h-2 rounded-full lg:inline`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center absolute bottom-20 left-1/2 transform -translate-x-1/2 z-30">
          <div className="opening-hours md:hidden">
            <h6 className="text-tuya-yellow mb-1 small-text-spaced">OPENING HOURS</h6>
            <p className="text-white whitespace-nowrap">
              {navbarData.normalStartDay}-{navbarData.normalEndDay}:{' '}
              <b className="text-xs">{navbarData.normalInterval}</b>
            </p>
            <p className="text-white mb-5 whitespace-nowrap">
              {'' !== navbarData.weekendStartDay && '' !== navbarData.weekendEndDay && (
                <>
                  {navbarData.weekendStartDay}-{navbarData.weekendEndDay}:{' '}
                  <b className="text-xs">{navbarData.weekendInterval}</b>
                </>
              )}
            </p>
            <Link to="/reservation">
              <Button>RESERVATION</Button>
            </Link>
          </div>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bar-wrapper z-30 hidden md:block">
          <div className="main-bar bg-tuya-slate" />
          <div className="loading-bar bg-white absolute top-0" />
        </div>
      </div>
      <div className="img-wrapper w-full absolute top-0 left-0 z-1">
        <div className="bg-black w-full h-full absolute z-10 opacity-50" />
        <AnimatePresence>
          <motion.img
            initial={{ x: '100%', zIndex: 4, ease: 'easeOut' }}
            animate={{ x: 0, zIndex: 4, ease: 'easeOut' }}
            exit={{ x: '-80%', zIndex: 2, ease: 'easeIn' }}
            transition={{ duration: 0.7 }}
            src={currentItem.image.sizes.src}
            srcSet={currentItem.image.sizes.srcSet}
            alt={currentItem.tabTitle}
            key={currentItem.id}
            className="w-full h-full object-cover bg-gray-100 absolute top-0 left-0"
          />
        </AnimatePresence>
      </div>
    </motion.section>
  )
}

const Section2 = ({ data }) => {
  const [isExploreFoodHovered, setIsExploreFoodHovered] = useState(true)
  const [isDrinksHovered, setIsDrinksHovered] = useState(true)
  const {
    mainTitle,
    mainParagraph,
    carousel,
    firstCardTitle,
    firstCardLink,
    firstCardImage,
    secondCardTitle,
    secondCardLink,
    secondCardImage,
    menuImage,
    menuParagraph,
    menuSmallText,
    secondCardSmallText,
  } = data
  return (
    <section className="section-2 pt-14 md:pt-28">
      <div className="wrapper">
        <div className="description lg:p-8">
          <h2 className="text-tuya-dark mb-3 md:mb-6">{mainTitle}</h2>
          <p className="text-grey-400 font-light">{mainParagraph}</p>
        </div>
        <Slider carousel={carousel}>
          <NextSlide />
          <CurrentSlide />
          <MobileNav />
        </Slider>
        <Link to={firstCardLink} className="explore-food h-full w-full relative lg:hidden block">
          <div
            className="px-8 py-6 w-full h-full
             absolute z-10 bottom-0 flex flex-col justify-between"
          >
            <div variants={variantsRevealParent}>
              <AnimatedPattern className="text-tuya-slate-light w-full" />
            </div>

            <div key="explore-food-text">
              <p key="food" className="text-6xl text-tuya-slate-light">
                Food
              </p>
              <p key="for" className="text-6xl text-tuya-slate-light">
                for
              </p>
              <p key="sharing" className="text-6xl text-tuya-slate-light">
                Sharing
              </p>
            </div>
            <div className="justify-self-end">
              <p className="text-tuya-dark text-lg mb-2">{firstCardTitle}</p>
              <Arrow className="w-3 h-3 text-tuya-dark" />
            </div>
          </div>
          <div custom={{ delay: 0.5 }} className="bg-grey-200 absolute w-full h-full bottom-0" />
        </Link>
        <motion.div
          className="explore-food h-full w-full relative hidden lg:block "
          onHoverStart={() => setIsExploreFoodHovered(false)}
          onHoverEnd={() => setIsExploreFoodHovered(true)}
          initial={false}
          animate={isExploreFoodHovered ? 'open' : 'closed'}
        >
          <Link to={firstCardLink}>
            <motion.div
              className="px-8 py-6 w-full h-full
             absolute z-10 bottom-0 flex flex-col justify-between "
            >
              <motion.div variants={variantsRevealParent}>
                <AnimatedPattern
                  variants={patternReveal}
                  className="text-tuya-slate-light w-full"
                />
                <AnimatedPattern
                  variants={patternReveal}
                  className="text-tuya-slate-light w-full"
                />
              </motion.div>
              <motion.div key="explore-food-text" variants={variantsRevealParent}>
                <motion.p
                  key="food"
                  variants={variantsReveal}
                  className="text-6xl text-tuya-slate-light"
                >
                  Food
                </motion.p>
                <motion.p
                  key="for"
                  variants={variantsReveal}
                  className="text-6xl text-tuya-slate-light"
                >
                  for
                </motion.p>
                <motion.p
                  key="sharing"
                  variants={variantsReveal}
                  className="text-6xl text-tuya-slate-light"
                >
                  Sharing
                </motion.p>
              </motion.div>

              <div className="justify-self-end">
                <p className="text-tuya-dark text-lg mb-2">{firstCardTitle}</p>
                <AnimatedArrow isHovered={!isExploreFoodHovered} className="h-3 text-tuya-dark" />
              </div>
            </motion.div>
            <motion.div
              variants={backgroundVariants}
              custom={{ delay: 0.5 }}
              className="bg-grey-200 absolute w-full bottom-0"
            />
          </Link>
          <div className="w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={firstCardImage.sizes.src}
              srcSet={firstCardImage.sizes.srcSet}
              alt={firstCardTitle}
            />
          </div>
        </motion.div>
        <div className="explore-drinks-v-after" />

        <MotionLink
          to={secondCardLink}
          onHoverStart={() => setIsDrinksHovered(false)}
          onHoverEnd={() => setIsDrinksHovered(true)}
          initial={false}
          animate={isDrinksHovered ? 'open' : 'closed'}
          className="explore-drinks h-full w-full relative flex flex-col"
        >
          <p style={{ fontSize: 10, letterSpacing: 0.83 }} className="italic text-tuya-dark mb-2">
            {secondCardSmallText}
          </p>
          <div
            className="px-7 py-6 w-full h-full
             absolute z-10 bottom-0 flex"
          >
            <div className="w-full self-end ">
              <p className="text-white text-lg mb-2">{secondCardTitle}</p>
              <AnimatedArrow isHovered={!isDrinksHovered} className="h-3 text-white" />
            </div>
          </div>
          <motion.div
            variants={backgroundVariants}
            custom={{ initial: 0, full: 145 }}
            className="bg-tuya-slate absolute w-full bottom-0"
          />

          <div className="w-full flex-1 max-h-full">
            <img
              className="object-cover object-bottom w-full h-full"
              src={secondCardImage.sizes.src}
              srcSet={secondCardImage.sizes.srcSet}
              alt={secondCardTitle}
            />
          </div>
        </MotionLink>
        <div className="picture-line-h-before" />
        <div className="picture-line-h-after" />
        <div className="picture-line-v-before" />
        <div className="h-full w-full picture-wrapper">
          <div className="md:w-full picture">
            <img
              className="object-cover w-full h-full"
              src={menuImage.sizes.src}
              srcSet={menuImage.sizes.srcSet}
              alt="Menu"
            />
          </div>
          <p
            style={{ fontSize: 10, letterSpacing: 0.83 }}
            className="italic text-tuya-dark mt-2 text-right opacity-60 mb-10"
          >
            {menuSmallText}
          </p>
          <div className="md:hidden">
            <Link to="/food" className="text-tuya-slate-light text-3xl text-left">
              Check the Menu
              <AnimatedArrow className="h-3 text-tuya-dark mt-2" />
            </Link>
          </div>
        </div>
        <div className="h-full w-full check-menu lg:flex lg:items-center">
          <div>
            <Pattern className="text-tuya-slate-light mb-4" />
            <p className="text-grey-400 text-lg font-light md:mb-8">{menuParagraph}</p>
            <Link to="/food" className="text-tuya-slate-light text-3xl hidden md:block text-left">
              Check the Menu
              <AnimatedArrow className="h-3 text-tuya-dark mt-2" />
            </Link>
          </div>
        </div>
        <div className="h-full w-full reservation flex justify-end items-center lg:justify-center ">
          <Link to="/reservation">
            <Button isDark isVertical>
              RESERVATION
            </Button>
          </Link>
        </div>
      </div>
    </section>
  )
}

const IndexPage = ({ data, location }) => {
  const { sliderItems, ...section2Data } = data.datoCmsHome
  return (
    <Layout className="index-page" pathname={location.pathname}>
      <SEO title="Mediterranean Cuisine Adventure" />
      <HeroSection items={sliderItems} navbarData={data.datoCmsResturantInformation} />
      <Section2 data={section2Data} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    datoCmsHome {
      sliderItems {
        id
        title
        tabTitle
        subtitle
        pageSlug
        image {
          sizes {
            srcSet
            src
          }
        }
      }
      carousel {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
        title
      }
      firstCardImage {
        sizes {
          src
          srcSet
        }
      }
      firstCardTitle
      firstCardLink
      mainParagraph
      mainTitle
      menuImage {
        sizes {
          srcSet
          src
        }
      }
      menuParagraph
      menuSmallText
      secondCardSmallText
      secondCardLink
      secondCardTitle
      secondCardImage {
        sizes {
          src
          srcSet
        }
      }
    }
    datoCmsResturantInformation {
      normalStartDay
      normalEndDay
      normalInterval
      weekendStartDay
      weekendEndDay
      weekendInterval
    }
  }
`

export default IndexPage
