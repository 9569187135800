import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import AnimatedArrow from './animated-arrow'
import Arrow from '../images/arrow.inline.svg'

const variants = {
  initial: {
    // opacity: 0,
    x: -50,
  },
  animate: {
    // opacity: 1,
    x: 0,
  },
  exit: {
    // opacity: 0,
    x: 0,
  },
}

const reversedVariants = {
  initial: {
    // opacity: 0,
    x: 50,
  },
  animate: {
    // opacity: 1,
    x: 0,
  },
  exit: {
    // opacity: 0,
    x: 0,
  },
}

export default function Slider({ children, carousel, reversed }) {
  const [currentSlides, setCurrentSlides] = useState([0, 1])
  function handleNextSlide() {
    console.log('Next', carousel)

    setCurrentSlides([
      (currentSlides[0] + 1) % carousel.length,
      (currentSlides[1] + 1) % carousel.length,
    ])
  }
  function handlePrevSlide() {
    console.log('Prev')
    setCurrentSlides([
      (currentSlides[0] - 1 + carousel.length) % carousel.length,
      (currentSlides[1] - 1 + carousel.length) % carousel.length,
    ])
  }

  return (
    <AnimatePresence>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          carousel,
          currentSlides,
          setCurrentSlides,
          reversed,
          handleNextSlide,
          handlePrevSlide,
        }),
      )}
    </AnimatePresence>
  )
}

export function NextSlide({
  carousel,
  currentSlides,
  setCurrentSlides,
  sliderId,
  reversed,
  handleNextSlide,
  noGrayscale,
  className,
  hFull = 'h-full',
}) {
  const [fixedHeight, setFixedHeight] = useState('')
  const windowSize = useWindowSize()

  useEffect(() => {
    const currentSlider = document.getElementsByClassName('current-slide-' + sliderId)
    if ('' === hFull && currentSlider.length > 0) {
      const sliderHeight = currentSlider[0].children[0].clientHeight
      if (sliderHeight !== undefined) {
        setFixedHeight(sliderHeight)
      }
    }
  }, [windowSize])

  return (
    <motion.div
      key="next-slide"
      // style={{height :  hFull ? '100%' : carousel[currentSlides[1]].image.height + 'px'}}
      style={{ height: fixedHeight ? fixedHeight + 'px' : 'auto' }}
      className={`next-slide${sliderId ? '-' + sliderId : ''} ${hFull} w-full ${
        className ? className : ''
      }`}
    >
      <motion.div key={carousel[currentSlides[1]].id} className={`w-full h-full mb-3 img-wrapper`}>
        <button onClick={handleNextSlide} className={`w-full h-full`}>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`object-cover object-right w-full h-full ${
              noGrayscale ? '' : 'grayscale'
            } transition-all`}
            src={carousel[currentSlides[1]].image.sizes.src}
            srcSet={carousel[currentSlides[1]].image.sizes.srcSet}
            alt={carousel[currentSlides[1]].title}
          />
        </button>
      </motion.div>
      <button
        onClick={handleNextSlide}
        className={`text-tuya-dark hidden md:flex ${
          reversed ? 'mr-auto xl:ml-3' : 'ml-auto xl:mr-3 '
        } items-center text-xs font-semibold focus:outline-none group`}
      >
        {reversed ? (
          <>
            {currentSlides[1] + 1}
            <AnimatedArrow className="h-3 w-5 ml-2 " />
          </>
        ) : (
          <>
            <AnimatedArrow className="h-3 w-5 mr-2 transform -scale-x-1" />
            {currentSlides[1] + 1}
          </>
        )}
      </button>
    </motion.div>
  )
}

export function CurrentSlide({
  carousel,
  currentSlides,
  setCurrentSlides,
  sliderId,
  reversed,
  handleNextSlide,
  handlePrevSlide,
  className,
  hFull = 'h-full',
}) {
  return (
    <motion.div
      key="current-slide"
      className={`current-slide${sliderId ? '-' + sliderId : ''} ${hFull} w-full ${
        className ? className : ''
      }`}
    >
      <motion.div
        key={carousel[currentSlides[0]].id}
        className={`w-full ${hFull} mb-3 relative`}
        onPanEnd={(_, pointInfo) => {
          if (pointInfo.velocity.x > 0) {
            handleNextSlide()
          } else {
            handlePrevSlide()
          }
        }}
      >
        <motion.img
          variants={reversed ? reversedVariants : variants}
          initial="initial"
          animate="animate"
          exit="exit"
          className={`object-cover w-full ${hFull}`}
          src={carousel[currentSlides[0]].image.sizes.src}
          srcSet={carousel[currentSlides[0]].image.sizes.srcSet}
          alt={carousel[currentSlides[0]].title}
        />
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.3 }}
          style={{
            writingMode: 'vertical-lr',
            fontSize: 10,
            letterSpacing: 1.43,
          }}
          className="absolute bottom-8 left-6 text-white transform rotate-180 font-bold "
        >
          {carousel[currentSlides[0]].title}
        </motion.span>
      </motion.div>
      <button
        onClick={handlePrevSlide}
        className={`text-tuya-dark hidden md:flex items-center text-xs font-semibold focus:outline-none group  ${
          reversed ? 'ml-auto' : 'mr-auto xl:ml-3 2xl:ml-0'
        }`}
      >
        {reversed ? (
          <>
            <AnimatedArrow className="w-5 h-3 mr-2 transform -scale-x-1" />
            {currentSlides[0] + 1}
          </>
        ) : (
          <>
            {currentSlides[0] + 1}
            <AnimatedArrow className="w-5 h-3 ml-2 " />
          </>
        )}
      </button>
    </motion.div>
  )
}

export const MobileNav = ({
  carousel,
  currentSlides,
  setCurrentSlides,
  mt,
  className,
  sliderId,
  handleNextSlide,
  handlePrevSlide,
}) => {
  return (
    <div
      className={`mobile-arrows${
        sliderId ? '-' + sliderId : ''
      } flex justify-center md:hidden space-x-12 ${mt ? 'mt-' + mt : '-mt-3'} ${className}`}
    >
      <button onClick={handlePrevSlide} className="p-3 outline-none ">
        <Arrow className="w-4 h-4 transform rotate-180" />
      </button>
      <button onClick={handleNextSlide} className="p-3 outline-none ">
        <Arrow className="w-4 h-4" />
      </button>
    </div>
  )
}
