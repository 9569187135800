import React, { useState, useRef } from 'react'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

const AnimatedPattern = ({ isHovered, className }) => {
  const [isParentHover, setIsParentHover] = useState(false)
  const svgRef = useRef()
  const handleMouseOver = () => setIsParentHover(true)
  const handleMouseOut = () => setIsParentHover(false)
  useEffect(
    () => {
      const node = svgRef.current.parentNode
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [], // Recall only if ref changes
  )
  return (
    <motion.svg ref={svgRef} reviewBox="0 0 16 9.57" className={className}>
      <motion.polygon
        points="11.83 9.57 11.08 8.91 14.67 4.79 11.08 0.66 11.83 0 16 4.79 11.83 9.57"
        initial={false}
        animate={{ x: isHovered || isParentHover ? 0 : -4.89 }}
        fill="currentColor"
      />
      <motion.line
        initial={false}
        animate={{ x2: isHovered || isParentHover ? 14.89 : 10 }}
        y1="4.79"
        x2="14.89"
        y2="4.79"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </motion.svg>
  )
}

export default AnimatedPattern
